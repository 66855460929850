// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/icons/alert.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/icons/alert.tsx");
  import.meta.hot.lastModified = "1726716722997.0098";
}
// REMIX HMR END

export function DangerAlert() {
  return <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M10 18C5.58172 18 2 14.4183 2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10C18 14.4183 14.4183 18 10 18ZM9 8.9996C9 9.55188 9.44772 9.9996 10 9.9996C10.5523 9.9996 11 9.55188 11 8.9996V6.9996C11 6.44732 10.5523 5.9996 10 5.9996C9.44772 5.9996 9 6.44732 9 6.9996V8.9996ZM9 12.9996C9 13.5519 9.44772 13.9996 10 13.9996C10.5523 13.9996 11 13.5519 11 12.9996C11 12.4473 10.5523 11.9996 10 11.9996C9.44772 11.9996 9 12.4473 9 12.9996Z" fill="#D92F28" />
    </svg>;
}
_c = DangerAlert;
export function InfoAlert() {
  return <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.9996 16.7998C11.7609 16.7998 11.532 16.705 11.3632 16.5362C11.1944 16.3674 11.0996 16.1385 11.0996 15.8998V11.6998C11.0996 11.4611 11.1944 11.2322 11.3632 11.0634C11.532 10.8946 11.7609 10.7998 11.9996 10.7998C12.2383 10.7998 12.4672 10.8946 12.636 11.0634C12.8048 11.2322 12.8996 11.4611 12.8996 11.6998V15.8998C12.8996 16.1385 12.8048 16.3674 12.636 16.5362C12.4672 16.705 12.2383 16.7998 11.9996 16.7998Z" fill="#405AE2" />
      <path d="M10.7998 8.4002C10.7998 8.08194 10.9262 7.77671 11.1513 7.55167C11.3763 7.32662 11.6815 7.2002 11.9998 7.2002C12.3181 7.2002 12.6233 7.32662 12.8483 7.55167C13.0734 7.77671 13.1998 8.08194 13.1998 8.4002C13.1998 8.71846 13.0734 9.02368 12.8483 9.24872C12.6233 9.47377 12.3181 9.6002 11.9998 9.6002C11.6815 9.6002 11.3763 9.47377 11.1513 9.24872C10.9262 9.02368 10.7998 8.71846 10.7998 8.4002Z" fill="#405AE2" />
      <path fillRule="evenodd" clipRule="evenodd" d="M20.3996 12.0001C20.3996 13.1032 20.1823 14.1955 19.7602 15.2146C19.3381 16.2338 18.7193 17.1598 17.9393 17.9398C17.1593 18.7198 16.2333 19.3385 15.2141 19.7607C14.195 20.1828 13.1027 20.4001 11.9996 20.4001C10.8965 20.4001 9.8042 20.1828 8.78507 19.7607C7.76593 19.3385 6.83992 18.7198 6.05991 17.9398C5.2799 17.1598 4.66116 16.2338 4.23902 15.2146C3.81688 14.1955 3.59961 13.1032 3.59961 12.0001C3.59961 9.77228 4.48461 7.63571 6.05991 6.0604C7.63522 4.48509 9.77179 3.6001 11.9996 3.6001C14.2274 3.6001 16.364 4.48509 17.9393 6.0604C19.5146 7.63571 20.3996 9.77228 20.3996 12.0001ZM18.5996 12.0001C18.5996 13.7505 17.9043 15.4293 16.6665 16.667C15.4288 17.9047 13.75 18.6001 11.9996 18.6001C10.2492 18.6001 8.57044 17.9047 7.3327 16.667C6.09496 15.4293 5.39961 13.7505 5.39961 12.0001C5.39961 10.2497 6.09496 8.57093 7.3327 7.33319C8.57044 6.09545 10.2492 5.4001 11.9996 5.4001C13.75 5.4001 15.4288 6.09545 16.6665 7.33319C17.9043 8.57093 18.5996 10.2497 18.5996 12.0001Z" fill="#405AE2" />
    </svg>;
}
_c2 = InfoAlert;
export function SuccessAlert() {
  return <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="32" height="32" rx="16" fill="#303746" />
      <mask id="mask0_2868_1383" maskUnits="userSpaceOnUse" x="4" y="4" width="24" height="24">
        <rect x="4" y="4" width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_2868_1383)">
        <path d="M14.6 20.6L21.65 13.55L20.25 12.15L14.6 17.8L11.75 14.95L10.35 16.35L14.6 20.6ZM16 26C14.6167 26 13.3167 25.7373 12.1 25.212C10.8833 24.6873 9.825 23.975 8.925 23.075C8.025 22.175 7.31267 21.1167 6.788 19.9C6.26267 18.6833 6 17.3833 6 16C6 14.6167 6.26267 13.3167 6.788 12.1C7.31267 10.8833 8.025 9.825 8.925 8.925C9.825 8.025 10.8833 7.31233 12.1 6.787C13.3167 6.26233 14.6167 6 16 6C17.3833 6 18.6833 6.26233 19.9 6.787C21.1167 7.31233 22.175 8.025 23.075 8.925C23.975 9.825 24.6873 10.8833 25.212 12.1C25.7373 13.3167 26 14.6167 26 16C26 17.3833 25.7373 18.6833 25.212 19.9C24.6873 21.1167 23.975 22.175 23.075 23.075C22.175 23.975 21.1167 24.6873 19.9 25.212C18.6833 25.7373 17.3833 26 16 26Z" fill="#3A8348" />
      </g>
    </svg>;
}
_c3 = SuccessAlert;
var _c, _c2, _c3;
$RefreshReg$(_c, "DangerAlert");
$RefreshReg$(_c2, "InfoAlert");
$RefreshReg$(_c3, "SuccessAlert");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;